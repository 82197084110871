import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Stack } from "@mui/material";
import { Span } from "./Typography";

const ModalDialog = ({ openModal, handleClose, title, contentText, handleButtonOk, buttonText }) => {

    return (
        <Dialog open={openModal}
            // fullWidth={true}
            // maxWidth={'md'}
            // style={{ height: '25%' }}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle style={{ paddingBottom: 5, alignSelf: 'center' }}>{title}</DialogTitle>
            <DialogContent>
                <Stack direction={'column'}>
                    <DialogContentText style={{ paddingBottom: 10, alignSelf: 'center' }}>{contentText}</DialogContentText>
                    <Stack direction={'row'} spacing={2} sx={{ justifyContent: 'center', marginTop: 2 }} >
                        <>
                            <Button color="primary" variant="contained" type="submit" size='large' onClick={handleButtonOk}>
                                <Span sx={{ textTransform: "capitalize" }}>{buttonText}</Span>
                            </Button>
                        </>

                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default ModalDialog;