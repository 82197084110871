export const navigations = [
  { name: 'Dashboard', path: '/dashboard', icon: 'dashboard' },
  { name: 'Projects', path: '/post/CreateProject', icon: 'movie' },
  // {
  //   name: 'Auditions',
  //   icon: 'launch',
  //   children: [
  //     { name: 'New Audition', iconText: 'NA', path: '/post/CreatePost' },
  //     { name: 'Auditions', iconText: 'AL', path: '/post/ListPost' }
  //   ]
  // },
  { name: 'Create New Audition', path: '/post/CreatePost', icon: 'note' },
  { name: 'Auditions', path: '/post/ListPost', icon: 'list' },
  { name: 'Actors List', path: '/users/UserList', icon: 'people' },
  { name: 'Feedback', path: '/users/Feedback', icon: 'feedback' },
  { name: 'Configure', path: '/users/Configure', icon: 'colorize' },
  { name: 'Actors Management', path: '/users/UserManagement', icon: 'manageaccount' },
];

export const NonDirectorNavigation = [
    { name: 'Dashboard', path: '/dashboard', icon: 'dashboard' },
  { name: 'Projects', path: '/post/CreateProject', icon: 'movie' },
  // {
  //   name: 'Auditions',
  //   icon: 'launch',
  //   children: [
  //     { name: 'New Audition', iconText: 'NA', path: '/post/CreatePost' },
  //     { name: 'Auditions', iconText: 'AL', path: '/post/ListPost' }
  //   ]
  // },
  { name: 'Create New Audition', path: '/post/CreatePost', icon: 'note' },
    { name: 'Auditions', path: '/post/ListPost', icon: 'list' },
 { name: 'Actors List', path: '/users/UserList', icon: 'people' },
  { name: 'Feedback', path: '/users/Feedback', icon: 'feedback' },
{ name: 'Configure', path: '/users/Configure', icon: 'colorize' },
]

export const ProductionUserNavigation = [
  { name: 'Dashboard', path: '/dashboard', icon: 'dashboard' },
  { name: 'Projects', icon: 'movie', path: '/post/CreateProject' },
  { name: 'Auditions', icon: 'list', path: '/post/ListPost' }
]
