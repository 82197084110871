import {
    IconButton,
    Button,
    TextField,
    Autocomplete,
    DialogTitle,
    DialogContentText,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    styled,
    Grid,
    useMediaQuery,
    CircularProgress,
    Switch
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { endPointName } from 'apis/apiList';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { CallAPI } from 'apis/callAPI';
import { catchError } from 'app/firebase/Crashlytics';
import ValidationPopUp from './ValidationPopUp';
import useAuth from 'app/hooks/useAuth';
import ServerErrorModal from './ServerErrorModal';

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
}));

export const UserEditableModal = ({
    postData,
    userId,
    handleCloseDialog,
    openEditDialog,
    selectedRowData,
    onSubmitUser
}) => {
    const { logout, user } = useAuth();
    const userFromSession = JSON.parse(sessionStorage.getItem("userJSON"));
    const accessToken = window.localStorage.getItem('accessToken')
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [stage, setStage] = useState(null);
    const [status, setStatus] = useState(null);
    const [auditionTypeValue, setAuditionTypeValue] = useState(null);
    const [remarks, setRemarks] = useState();
    const [isRemarksError, setIsRemarksError] = useState(false);
    const [popUp, setPopUp] = useState(false);
    const [APILoading, setAPILoading] = useState(false);
    const [isOnline, setOnline] = useState(true);
    const [errorPopUp, setErrorPopUp] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        setOnline(navigator.onLine)
    }, [])

    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
    });

    useEffect(() => {
        try {
            if (accessToken !== null) {
                if (isOnline) {

                } else {
                    setErrorMsg('No Internet')
                    setErrorPopUp(true)
                }
            } else {
                logout()
            }
        } catch (error) {
            catchError(error, 'UserEditableModal_1');
        }
    }, [accessToken])

    const handleServerError = () => {
        try {
            setErrorPopUp(false)
            setErrorMsg('')
        } catch (error) {
            catchError(error, "UserEditableModal_2")
        }
    }

    const onClickActivate = async () => {
        try {
            const payload = {
                UserId: selectedRowData.userId,
                ActivateDeactivateRemark: remarks,
                ActivateDeactivateBy: userFromSession.adminUserId,
                ActivateDeactivatePlatform: "webpanel",
                IsActivateDeactivate: true
            }
            let response = await CallAPI(endPointName.ActivateActor, "post", payload);
            if (response.statusCode == 200) {
                handleCloseDialog('1')
            } else {
                setErrorMsg('Server Error');
                setErrorPopUp(true);
                handleCloseDialog();
            }
        } catch (error) {
            catchError(error, "UserEditableModal_3")
        }
    }

    return (
        <>
            <Dialog
                open={openEditDialog}
                fullWidth={true}
                maxWidth={'md'}
                onClose={handleCloseDialog}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title" marginBottom={2}>
                    {selectedRowData.firstName + ' ' + selectedRowData.lastName}
                    {/* - ({selectedRowData.isActive == true ? 'Is Active' : 'Not Active'}) */}
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            // top: 8,
                            color: "grey",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>
                    </DialogContentText> */}
                    <Grid container columns={{ xs: 6, sm: 8, md: 12 }}>
                        <Grid item xs={12} style={{ padding: 5 }}>
                            <TextField
                                margin="dense"
                                id="name"
                                label="Remark"
                                type="text"
                                multiline={true}
                                placeholder={"Enter remark"}
                                maxLength="200"
                                // disabled={selectedRowData.applicationStatus === 3 && userFromSession.organizationId === 4 ? true : false}
                                onChange={(e) => { setRemarks(e.target.value); setIsRemarksError(false) }}
                                rows={3}
                                style={{ width: '100%', marginTop: 0 }}
                                value={remarks}
                            // validators={["required"]}
                            // error={isRemarksError}
                            // helperText={isRemarksError ? "Remark is required" : ''}

                            />
                        </Grid>

                    </Grid>

                </DialogContent>
                <Divider style={{ backgroundColor: '#F7F7F7', }} />
                <DialogActions style={{ display: 'flex', justifyContent: isNonMobile == false && 'center' }}>
                    <Button disabled={selectedRowData.applicationStatus === 3 && (userFromSession.organizationId === 4) ? true : false} sx={{ ":hover": { backgroundColor: "#ea232a" }, backgroundColor: '#F06C6A' }} variant="contained" style={{ marginRight: 20, marginTop: 5, marginBottom: 5 }} onClick={() => onClickActivate()}>
                        {APILoading ?
                            <CircularProgress size={20} color='inherit' sx={{ alignItems: 'center' }} />
                            :
                            'Activate'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
            {
                popUp &&
                <ValidationPopUp
                    openModal={popUp}
                    handleClose={() => {
                        setPopUp(false);
                        setStage(null);
                        setStatus(null);
                        setAuditionTypeValue(null);
                    }}
                    contentText={'Stage cannot be modified since the selection process of this performer is completed.'}
                />
            }
            {
                errorPopUp &&
                <ServerErrorModal
                    popUpFor={errorMsg}
                    openAlert={errorPopUp}
                    handleCloseAlert={handleServerError}
                />
            }
        </>
    );
};
