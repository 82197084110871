import { Box, Grid, styled, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import DocumentTitle from 'react-document-title';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';


const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
}));

const TermsCondition = () => {

    const navigate = useNavigate();


    return (
        <DocumentTitle title={'Terms & Condition'}>
            <>
                <Helmet>

                    <link rel="stylesheet" type="text/css" href="css/styles.css" />
                </Helmet>
                <div>

                    <body style={{
                        fontFamily: 'sans-serif,Arial',
                        margin: 0,
                        padding: 0,
                    }}>
                        <section id="logo-home">
                            <img src="images/yrf-casting-logo.png" />
                        </section>
                        <header style={{
                            backgroundColor: '#ffffff',
                            color: '#000000',
                            textAlign: 'center',
                            padding: '20px'
                        }}>
                            <h1>Terms and Conditions</h1>
                        </header>
                        <main style={{ margin: '20px' }}>
                            <p>Version: 1</p>
                            <p>This mobile application YRF Casting is managed and operated by Yash Raj Films Private Limited <b>(Company, we, us, our, YRF)</b>. The Company provides you with customized services whereby we allow interested users to submit the requisite information for any professional opportunities available at YRF, and, may also offer casting notices, forums, bulletin boards, blogs or other interactive areas for our users to interact and communicate with individuals posting casting notices <b>(Services)</b>. </p>
                            <p>By accessing, downloading, using, or registering on our mobile application/ YRF Casting <b>(Platform)</b>, you agree to be governed by these Terms and Conditions <b>(Terms)</b> and the Privacy Policy https://www.yrfcasting.com/privacy-policy.html of the Company. You should carefully go through the Terms before you decide to use the Platform.  </p>
                            <p>This Privacy Policy must be read in conjunction and together with the Terms and Conditions, available at: <a href="https://yrfcasting.com/termsofservice" style={{ color: 'grey' }}>https://www.yrfcasting.com/terms-and-condition</a>. The defined terms shall have the meaning ascribed to them within the Terms and Conditions. </p>

                            <ol>
                                <li><strong>Services.</strong> <br />
                                    a.	To register on the Platform, and avail our Services, you must be of at least 18 years of age and have the capacity to enter into this agreement. <br />
                                    b.	You hereby confirm that all the information furnished is truthful and accurate, and does not suffer from any deficiencies. <br />
                                    c.	For creation of an account, registration on the Platform, you will have to provide a valid mobile number, and you shall receive an OTP, for authentication and validation of your profile. This profile created on the Platform, will be available for your exclusive access, and you would be able to interact with us, upload requisite data, and avail such other Services which are functional on the Platform. You are responsible for safeguarding your account and agree not to disclose your login details to anyone. Services on the Platform, will only become available to you, upon successful completion and registration on the Platform. <br />
                                    d.	You agree that you shall be solely responsible for any activities or actions made through your account. You will immediately notify the Company of any unauthorized use of your account, upon becoming aware of any such discrepancy. Without limiting any other terms of this Terms, you may not use false identities or impersonate any other person or use a username or phone number that you are not authorized to use. <br />
                                    e.	You shall request YRF, by notification via e-mail to the e-mail ID set out in the ‘Grievance Officer’ section below, to block Your account on the Platform, and change the credentials immediately for such account, if Your device bearing the Platform has been lost or stolen.<br />
                                    f.	The Services provided by us is only for your personal and non-commercial use and should not be shared with individuals otherwise. This Platform is intended for professional use, solely in the context of seeking casting opportunities with Us. You are encouraged to exercise caution and conduct due diligence before engaging in casting opportunities or sharing personal data via the Platform.<br /><br />
                                </li>
                                <li><strong>Platform Usage. </strong><br />
                                    a.	You agree, acknowledge, and confirm that any data and/or requests submitted by you through the Platform have been provided by you, on a voluntary basis. You further acknowledge that the mere submission of any data and/or requests by you on the Platform, do not, in any way amount to any guarantee or assurance of casting call backs, offers, or other acceptance by YRF, for any role or otherwise.  <br />
                                    b.	You understand and accept that not all casting calls placed / offered on the Platform by YRF are available to all users, and/or in all geographic areas, and you may not be eligible for consideration thereof. YRF reserves the right to determine the eligibility of any user for any role / casting call / audition, at its sole discretion.<br /><br />
                                </li>
                                <li><strong>User Content:</strong><br />
                                    a.	You understand that you may be required to submit photos, videos, snippets of your audition tapes, details of previous work experience <b>(User Content)</b>, in order to determine your suitability for any casting opportunities. <br />
                                    b.	You may upload such data as requested by YRF through the Platform, or you may further share any hyperlinks of your previous performances, auditions, available at any other video hosting application. YRF does not control the availability of such User Content residing on third party platforms, and you shall be subject to the terms and conditions of such third-party platforms. We advise you to familiarize yourself with such terms and conditions, and abide by them. <br />
                                    c.	You shall retain ownership and control of the User Content You submit on the Platform, Accordingly, by submitting any User Content, the User represents and warrants to YRF that: (a) the User Content is non-infringing, or violative of any rights of any third party and/or any applicable laws; (b) the User has the right and authority to submit and deliver on the Platform, and to YRF, the User Content, for the intended purposes (as detailed below).  <br />
                                    d.	By submitting the User Content, You permit and grant YRF a non-exclusive, worldwide, license to use, reproduce, distribute, share, and display the content, within the Platform, and share the same with YRF’s employees, representatives, agents, and other YRF authorized third-parties <b>(YRF Personnel)</b>, for the purposes of review, evaluation / assessment, and comparison, to determine whether the User is suitable to be cast in any role in any content being developed and/or produced by YRF and/or any YRF Personnel.  <br />
                                    e.	The Users are solely responsible for the User Content submitted on the Platform. YRF shall have the right to remove / have removed, any User Content, including any material/post therein, which may not be in conformity with applicable law, or otherwise at YRF’s sole discretion.<br /><br />
                                </li>
                                <li><strong>Intellectual Property</strong><br />
                                    a.	All content made available vide the Platform, i.e., the logos, artistic works, and trademarks, are intellectual property of YRF and/ or its authorized licensors, protected under the applicable laws. No information, content, or material from the Platform including, without limitation, all of the page headers, images, illustrations, graphics, audio clips, video clips, texts, reports generated, trademarks, trade names, etc. may be copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way without Our or our authorized licensor’s express written permission. <br />
                                    b.	When you upload, submit, store, send, or receive content that may include feedback and/or the User Content, to or through the Platform, you give YRF and the YRF Personnel, a worldwide, perpetual license to use, host, store, reproduce, modify, create derivative works, communicate, publish, publicly perform, publicly display and distribute such content, including for the purpose identified in the ‘User Content’ section above. This license shall not expire even if the content is not used by YRF or the YRF Personnel. Such license shall continue even if you stop using the Platform, unless a request for removal / deletion has been placed by You, as set out in the Privacy Policy.<br />
                                    c.	All third party trademarks, logos, photographs, images, audio and audio-visual content, programming, and other intellectual property contained on or within the Platform are the property of the respective third parties, including the respective content owners, and may be protected by applicable copyright, trademark or other intellectual property laws and treaties. Each such third party expressly reserves all rights into such intellectual property. Use of third party software or services is subject to the terms and conditions of the applicable third party license agreements, and you agree to look solely to the applicable third party and not to YRF to enforce any of your rights in relation thereto. <br />
                                    d.	Note that celebrities, and sometimes others, may have a "right of publicity," which means that they may have a right to control commercial uses of their name, image, likeness, and other aspects of their identity. Although you may be a fan, you risk infringing celebrity rights if you use a celebrity name or likeness on the Platform and you don't have the celebrity's permission. YRF reserves the right to remove any User Content contained in or posted to the Platform that YRF determines in its sole discretion does or may allegedly infringe another person's copyright, trademark, celebrity material or other rights.<br />
                                    e.	The Users undertake to keep strictly confidential and not disclose or divulge, directly or indirectly, to any third party including but not limited to any person, press member/organization, social networking platform, website or any company whatsoever, in any manner whatsoever: (i) any information in relation published, disseminated, and/or uploaded by YRF on the Platform, including details of auditions and casting calls; and (ii) any materials uploaded by YRF in connection with any casting call, including (without limitation) any scripts, video clips, instructions, concept, title, story, screenplay, cast, research material, manuals, know-how, formulas, improvements, release date, budgets / financial information, any footage, equipment and technology, and other commercial and non-commercial information. <br />
                                    f.	The Platform enables you to technically share limited information about casting calls/ auditions to other persons via our WhatsApp API. However, the third party will not be permitted to access such information, unless they download the Platform on their devices for viewing this content. <br /><br />
                                </li>
                                <li><strong>General Terms</strong><br />
                                    a.	You agree to use all our Services, in accordance with the applicable laws, rules and regulations, or any other specific restrictions on the use of the Service or content therein. <br />
                                    b.	Any use of the data you provide to us is subject to your understanding and acceptance of our Privacy Policy. We may use your data, for the defined purposes, as listed out in the Privacy Policy, accessible at: https://yrfcasting.com/privacypolicy.<br />
                                    c.	To find more information about our Services, or if you need any assistance with our Services, please write to us at: contact@yrfcasting.com.<br />
                                    d.	YRF is committed to safeguarding the Services. For that reason, we reserve the right to make changes to the Services for maintenance at any time. YRF shall not bear any liability to you and/or to any third parties, for any loss of opportunity on account of such maintenance services. We will make attempts on a best-efforts basis to let you beware of any scheduled maintenance to such extent as is possible. <br />
                                    e.	The use and/or access of the Platform shall not constitute or be deemed to constitute an employee-employer relationship, partnership, association of persons, or agency relationship between any of the Users and YRF, and no User shall have any authority to bind YRF in any way. No User shall impersonate a representative of YRF, and/or hold themselves as having any authority to invite, receive, and/or collect any information from third parties, whether in connection with any casting calls and/or auditions, on behalf of YRF or otherwise.<br /><br />
                                </li>
                                <li><strong>Restrictions of Use</strong><br />
                                    a.	You agree not to: circumvent, remove, degrade, deactivate, or thwart any of the contents of our Service; use any robot, spider, scraper, or other means to access our Service. You also agree not to decompile, reverse engineer, and disassemble any software or other products or processes accessible through our Service. Additionally, you agree not to upload, post, e-mail or otherwise send or transmit any material designed to interrupt, destroy, or limit the functionality of our service. We may terminate or restrict your use of our service if you violate these Terms or engaged in any illegal or fraudulent use of our service.<br /><br />
                                    b.	Code of Conduct: You understand that the Platform is open to several individuals, and you must ensure that any statements, comments made vide your account, are not abusive, or discriminatory. You shall not engage in any behavior during your interaction with the Platform, which may amount to “Prohibited Behavior” as determined by Company, in its sole discretion and which includes, without limitation:<br />

                                    i.	Sexually Explicit Material (i.e., pornographic or erotic content, including icons, titles, or descriptions). We have a zero-tolerance policy against child sexual abuse imagery. If we become aware of any user exhibiting, publishing any child sexual abuse imagery, pornographic images on their account, we will be compelled to terminate the respective user accounts and report it to the appropriate authorities immediately; and,<br />
                                    ii.	Violence and Bullying (i.e., user shall not threaten, harass or bully other users, YRF Personnel, or make any inflammatory statements, in relation to violence, bullying including suicide); and,<br />
                                    iii.	Hate Speech (i.e., user shall not promote bigotry, discrimination, hatred, intolerance, or racism, or target against any person or groups of persons based on their race or ethnic origin, religion, disability, gender, age, nationality, veteran status, sexual orientation, or gender identity during their casting opportunities; and,<br />
                                    iv.	Sensitive Events (i.e., user shall not make any statements which may amount to capitalizing on or lacking reasonable sensitivity towards a natural disaster, atrocity, conflict, death, or other tragic event and,<br />
                                    v.	Unlawful Activity (i.e., user shall not use the Service for any illegal or unlawful purpose, including, without limitation, impersonation, defamatory statements, cyber-attacks, stalking and sexually exploiting any Peer); and,<br />
                                    vi.	User shall not use the Platform for any marketing purposes, dating, or any other ancillary purpose not in relation to casting opportunities;<br />
                                    vii.	User shall not impersonate any other person nor submit any information that is fake, inaccurate, and/or generated graphically, via computers and/or AI.<br />
                                    viii.	User will not engage in any form of antisocial, disruptive, or destructive acts, including "flaming", "spamming", "flooding", "trolling", "phishing" and "griefing" as those terms are commonly understood and used on the Internet.<br /><br />
                                    c.	Through your use of the Platform, you may be exposed to Content that you may find offensive, objectionable, harmful, inaccurate or deceptive. There may also be risks of dealing with underage persons, people acting under false pretences, international trade issues and foreign nationals. YRF is not liable to any user for hurting any sentiments or causing them any kind of hurt, during their interactions on the Platform. Users disclaims any recourse against Company and shall not hold Company liable for any content, which may have caused any form of hurt to such user.<br /><br />
                                </li>
                                <li><strong>THIRD PARTY SERVICES</strong><br />
                                    a.	You note that during your use of our Services, YRF may place advertisements or the likes on the Platform. You also agree to receive advertisement or other relevant information from YRF via e-mail or other methods that have been made available by you. You understand and agree that such advertisements may direct you to third party websites which may have their own policies which are different from that of ours. You note that such Services, to that limited extent, will be governed by the policies of such third parties. Please note that we do not own or operate the third-party sites, and we have not reviewed, and do not review, all the material, including goods or services, made available through third-party sites. We therefore urge you to kindly make an informed choice in interacting with such third-party sites and ensure that you are familiar with the policies of such sites before you proceed with engaging with such third-party sites.<br />
                                    b.	The availability of these links on the Platform does not represent, warrant, or imply that we endorse any Third-Party Sites or any materials, opinions, goods, or services available on them. Third party materials accessed through or used by means of the Third-Party Sites may also be protected by copyright and other intellectual property laws.<br /><br />
                                </li>
                                <li><strong>DISCLAIMER AND LIMITATION OF LIABILITY</strong><br />
                                    a.	To the maximum extent permitted by law, we will not be liable in any way for any loss or damage suffered by you through use of or access to the Services, or our failure to provide the Services. Our liability for negligence, breach of contract or contravention of any law as a result of our failure to provide the Services or any part of it, or for any problems with the Services, which cannot be lawfully excluded, is limited, at our option and to the maximum extent permitted by law.<br />
                                    b.	TO THE MAXIMUM EXTENT NOT PROHIBITED BY LAW, THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT ANY WARRANTIES OF ANY KIND, AND YRF AND ITS LICENSORS DISCLAIM ALL WARRANTIES, EXPRESS AND IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, AND WARRANTIES ARISING FROM COURSE OF DEALING, COURSE OF PERFORMANCE AND USAGE OF TRADE. YOUR USE OF THE SERVICES IS SOLELY AT YOUR OWN RISK. FURTHERMORE, YRF AND ITS LICENSORS DO NOT WARRANT THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR FREE, OR THAT DEFECTS WILL BE CORRECTED. YRF DOES NOT WARRANT THAT FILES AVAILABLE FOR DOWNLOAD WILL BE FREE OF VIRUSES, WORMS, TROJAN HORSES OR OTHER DESTRUCTIVE PROGRAMMING. ANY MATERIAL OBTAINED THROUGH THE USE OF THE SERVICES IS ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM ANY SUCH MATERIAL. WE MAKE NO WARRANTY REGARDING THE QUALITY, ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY CONTENT. <br /><br />
                                </li>
                                <li><strong>INDEMNITY</strong><br />
                                    a.	You agree to indemnify and hold YRF, YRF Personnel (and its officers, directors, employees, agents and representatives) harmless against all claims, liabilities, losses, penalties, expenses, damages and costs, including reasonable attorneys’ fees, resulting from or relating to your use of and access to the Platform.<br />
                                    b.	UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL YRF BE LIABLE FOR ANY DAMAGES (INCLUDING, WITHOUT LIMITATION, SPECIAL OR CONSEQUENTIAL DAMAGES) THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, THE MATERIALS IN AND AVAILABLE THROUGH THE APP, EVEN IF YRF HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. APPLICABLE LAW MAY NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN NO EVENT SHALL YRF's TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE), OR OTHERWISE) EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING THE PLATFORM.<br /><br />
                                </li>
                                <li><strong>SUSPENSION, TERMINATION, AND CANCELLATION</strong><br />
                                    a.	The Terms will continue to apply until your account is terminated by either you or the Company. You may end your legal agreement with the Company at any time for any reason by deactivating your accounts and discontinuing your use of the Services. However, the terms which survive general expiration shall continue to govern you. You agree that we have the right to immediately suspend your account on the Platform if we suspect any violation of the Terms or any applicable laws & regulations. <br /><br />
                                </li>
                                <li><strong>GOVERNING LAW AND DISPUTE RESOLUTION</strong><br />
                                    a.	These terms shall be governed by the laws of India, without regards to any conflict of law provisions. Any dispute arising out of these Terms shall be subject to the exclusive jurisdiction of the courts in Mumbai. <br /><br />
                                </li>
                                <li><strong>SEVERABILITY</strong><br />
                                    a.	If any provision or provisions of these Terms, shall be held to be illegal, invalid, or, unenforceable, the validity, legality, and enforceability of the remaining provisions shall remain in full force and effect.<br /><br />
                                </li>
                                <li><strong>CHANGES TO TERMS OF USE AND ASSIGNMENT</strong><br />
                                    a.	We may from time to time change these Terms. We will notify you at least 30 days before such changes apply to you. We may assign or transfer our agreement with you including our associated rights and obligations at any time and you agree to cooperate with us in connection with such an assignment or transfer. We recommend that you periodically check this page for any revised terms. Your continued use of the Services will be deemed to constitute your acceptance of all such revised terms.<br /><br />
                                </li>
                                <li><strong>GRIVEANCE OFFICER</strong> <br />
                                    a.	We have a Grievance Officer to address your concerns regarding data safety, privacy, and Platform usage concerns. We will resolve the issues raised by you within 30 (thirty) days from receiving them. We have created a method for you to get in touch with us and for us to address your concerns.<br />
                                    You may contact the Grievance Officer at any of the following:<br />
                                    Name: Sachin Kedar<br />
                                    Office Hours: 10 am to 6 pm<br />
                                    Email: sachink@yashrajfilms.com<br /><br />
                                </li>
                            </ol>
                            <br />
                            <br />
                        </main>
                        <footer style={{
                            display: 'flex'
                        }}>
                            <div style={{ flex: 0.8, justifyContent: 'flex-end', display: 'flex' }}>

                                <p>&copy; Yash Raj Films Pvt. Ltd.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="" onClick={() => navigate('/')}>Home</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href='' onClick={() => navigate("/privacypolicy")}>Privacy Policy</a></p>
                            </div>
                            <div style={{ flex: 0.5, textAlign: 'center', justifyContent: 'flex-end', display: 'flex' }}>
                                <p style={{ paddingRight: 20 }}>
                                    Powered by IFI Techsolutions
                                </p>
                            </div>
                        </footer>
                    </body>
                </div>
            </>
        </DocumentTitle >

    )
}

export default TermsCondition;