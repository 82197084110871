import Dexie from 'dexie';

const db = new Dexie('masterData');

db.version(1).stores({
    countries: 'countryId,country',
    city: 'cityId,city',
    // state: 'id,stateId,state,cityId,city'
    state: 'cityId,city',
    complexion: 'complexionId,complexionName',
    // Define other object stores for different types of master data as needed
});

export default db;