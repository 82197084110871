import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const Profile = Loadable(lazy(() => lazyRetry(() => import('./Profile'), "profile")));
const Settings = Loadable(lazy(() => lazyRetry(() => import('./Settings'), "settings")));

const lazyRetry = function (componentImport, name) {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false'
        );
        componentImport().then((component) => {
            window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
            resolve(component);
        }).catch((error) => {
            if (!hasRefreshed) { // not been refreshed yet
                window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
                return window.location.reload(); // refresh the page
            }
            reject(error); // Default error behaviour as already tried refresh

        });
    });
};

const profileRoutes = [
    {
        path: '/profile/Profile', element: <Profile />,
    },
    {
        path: '/profile/Settings', element: <Settings />,
    },
];

export default profileRoutes;