
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Stack } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Span } from "./Typography";

const ValidationPopUp = ({ openModal, handleClose, title, contentText, handleButtonOk, buttonText }) => {

    return (
        <Dialog
            open={openModal}
            // fullWidth={true}
            // maxWidth={'md'}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >

            <DialogTitle style={{ textAlign: "center" }}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 10,
                        color: "grey",
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <p style={{ textAlign: "center", marginTop: 20, paddingLeft: 40, paddingRight: 40, paddingTop: 20, paddingBottom: 20 }}>{contentText}</p>
            </DialogContent>
        </Dialog>

    )
}

export default ValidationPopUp;